import React, {FunctionComponent} from 'react';
import Link from 'next/link';

const Disclaimer: FunctionComponent = () => {

    return (
        <div className='extra-small-label py-2'>
            By submitting this form, you agree to receive marketing communications from SponsorPulse according to the <Link href='/terms-and-conditions'><a className='underline text-sp-primary'>Terms and Conditions</a></Link> and <Link href='/privacy-policy'><a className='underline text-sp-primary'>Privacy Policy</a></Link>. You can unsubscribe at any time.
        </div>
    );
};

export default Disclaimer;